import React, { Suspense, lazy, useEffect, useState } from "react";
import Sidebar from "./sidebar-white";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
const GoogleCSE = lazy(() => import("./GoogleCSE"));

export default function Navigation() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="navigation-header">
        <div className="navigation-background"></div>
        <table className="navigation-table">
          <tbody>
            <tr>
              <td className="navigation-td-1"></td>
              <td className="navigation-td-2">
                <a href="/">
                  <img
                    className="logo"
                    src="/images/HOME/Bppl-logo-with-box.webp"
                    loading="eager"
                    alt="logo"
                  />
                </a>
              </td>
              <td className="navigation-td-3">
                <a href="/">HOME</a>
              </td>
              <td className="navigation-td-4">
                <a href="/about-us/">ABOUT&nbsp;US</a>
              </td>
              <td className="navigation-td-5">
                <a href="/esg/">ESG</a>
              </td>
              <td className="navigation-td-6">
                <a href="/investor-relations/">INVESTOR&nbsp;RELATIONS</a>
              </td>
              {/* <td className="navigation-td-7">
                <a href="/faq-page/">FAQ</a>
              </td> */}
              <td className="navigation-td-8">
                <a href="/contacts/">CONTACTS</a>
              </td>
              <td className="navigation-td-9"></td>
              <td className="navigation-td-10">
                <Suspense fallback={<span></span>}>
                  <GoogleCSE />
                </Suspense>
              </td>
              <td className="navigation-td-11">
                <div className="mobile-font-icon" onClick={toggleSidebar}>
                  <FontAwesomeIcon icon={faAlignJustify} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {isSidebarOpen && (
        <div style={{ display: "block", position: "fixed", zIndex: "99999" }}>
          <Sidebar closeSidebar={toggleSidebar} />
        </div>
      )}
    </>
  );
}
