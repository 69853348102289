import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import GoogleCSE from "./GoogleCSE";

const Sidebar = ({ closeSidebar }) => {
  return (
    <div>
      <button className="close-btn-white" id="button">
        <div className="full-bac">
          <div className="sidenav-white open">
            <button
              className="close-btn-white"
              id="button"
              onClick={closeSidebar}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {/* Main Dropdown 1 */}
            <a href="/">
              <button className="sidenav-btn-white" id="button">
                <p className="sidenav-btn-link-white">HOME</p>
              </button>
            </a>

            {/* Main Dropdown 3 */}
            <a href="/about-us/">
              <button className="sidenav-btn-white" id="button">
                <p className="sidenav-btn-link-white">ABOUT US</p>
              </button>
            </a>

            {/* Main Dropdown 2 */}
            <a href="/esg/">
              <button className="sidenav-btn-white" id="button">
                <p className="sidenav-btn-link-white">ESG</p>
              </button>
            </a>

            {/* Main Dropdown 4 */}
            <a href="/investor-relations/">
              <button className="sidenav-btn-white" id="button">
                <p className="sidenav-btn-link-white">INVESTOR RELATIONS</p>
              </button>
            </a>

            {/* Main Dropdown 5 */}
            {/* <a href="/faq-page/">
              <button className="sidenav-btn-white" id="button">
                <p className="sidenav-btn-link-white">FAQ</p>
              </button>
            </a> */}

            {/* Main Dropdown 6 */}
            <a href="/contacts/">
              <button className="sidenav-btn-white" id="button">
                <p className="sidenav-btn-link-white">CONTACTS</p>
              </button>
            </a>

            <div className="sidenav-search-bar-white">
              <GoogleCSE />
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default Sidebar;
