import React, { useState, useEffect } from "react";
import "../../styling/templates.css";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useLocation } from "@reach/router";

function App() {
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberUserData, setRememberUserData] = useState(false); // Add state for rememberUserData
  const [hcaptchaToken, setHcaptchaToken] = useState(""); // State to store HCaptcha token

  const saveUserData = (formData) => {
    const userData = {
      name: formData.get("name"),
      email: formData.get("email"),
      website: formData.get("website"),
      rememberUserData: formData.get("rememberUserData"),
    };

    localStorage.setItem("userData", JSON.stringify(userData));
  };

  useEffect(() => {
    const savedUserData = localStorage.getItem("userData");

    if (savedUserData) {
      const userData = JSON.parse(savedUserData);
      document.querySelector("[name=name]").value = userData.name;
      document.querySelector("[name=email]").value = userData.email;
      document.querySelector("[name=website]").value = userData.website;
      document.querySelector("[name=rememberUserData]").checked =
        userData.rememberUserData; // Set the checked property for the checkbox
    }
  }, []);

  const handleCaptchaVerify = (token) => {
    // Store the HCaptcha token in state
    setHcaptchaToken(token);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append(
      "subject",
      formData.get("subject") || "BPPL Leave a Comment Form"
    );
    formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");

    // Check if the checkbox is checked before saving data
    if (formData.get("rememberUserData")) {
      saveUserData(formData);
    } else {
      // If the checkbox is unchecked, clear the saved data
      localStorage.removeItem("userData");
    }

    // Check if HCaptcha token is available before submitting the form
    if (!hcaptchaToken) {
      setErrorMessage("Please complete the CAPTCHA.");
      return;
    }

    // Add the HCaptcha token and current page URL to the form data for form submission only
    formData.append("hcaptchaToken", hcaptchaToken);
    formData.append("form_location", location.href);

    // Convert form data to an object to exclude HCaptchaToken from emails
    const object = Object.fromEntries(formData);
    const jsonToSend = { ...object };

    // Remove hcaptchaToken from the object so it's not included in the email data
    delete jsonToSend.hcaptchaToken;

    const json = JSON.stringify(jsonToSend); // Data sent to web3forms, excluding hcaptchaToken from email content

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      if (res.ok) {
        const response = await res.json();
        if (response.success) {
          // Set the success message and redirect
          setSuccessMessage("Form submitted successfully!");
          setErrorMessage(""); // Clear any previous error message
          window.location.href = formData.get("redirect");
        } else {
          setErrorMessage("Form submission failed. Please try again.");
          setSuccessMessage(""); // Clear any previous success message
        }
      } else {
        setErrorMessage("Form submission failed. Please try again.");
        setSuccessMessage(""); // Clear any previous success message
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setSuccessMessage(""); // Clear any previous success message
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit} id="bpplholdingsleaveacomment">
        <h1 className="rd-form-topic">Leave a Comment</h1>
        <p className="rd-from-para">
          Your email address will not be published. Required fields are marked *{" "}
        </p>

        <div className="input-col">
          <textarea
            name="message"
            rows="9"
            required
            className="textareaStyle"
            placeholder="Type here..."
            style={{
              fontSize: "15px",
              lineHeight: "24px",
              color: "#5f5f5f",
              fontFamily: '"Assistant", sans-serif',
              fontWeight: "400",
            }}
          ></textarea>
        </div>
        <div className="input-row">
          <div className="input-col">
            <input
              type="text"
              name="name"
              required
              className="inputStyle"
              placeholder="Name *"
              style={{
                fontSize: "15px",
                lineHeight: "24px",
                color: "#5f5f5f",
                fontFamily: '"Assistant", sans-serif',
                fontWeight: "400",
              }}
            />
          </div>
          <div className="input-col">
            <input
              type="email"
              name="email"
              required
              className="inputStyle"
              placeholder="Email *"
              style={{
                fontSize: "15px",
                lineHeight: "24px",
                color: "#5f5f5f",
                fontFamily: '"Assistant", sans-serif',
                fontWeight: "400",
              }}
            />
          </div>
          <div className="input-col">
            <input
              type="text"
              name="website"
              className="inputStyle"
              placeholder="Website"
              style={{
                fontSize: "15px",
                lineHeight: "24px",
                color: "#5f5f5f",
                fontFamily: '"Assistant", sans-serif',
                fontWeight: "400",
              }}
            />
          </div>
        </div>

        <div className="rd-from-para">
          <label>
            <input
              type="checkbox"
              name="rememberUserData"
              checked={rememberUserData}
              onChange={() => setRememberUserData(!rememberUserData)}
            />
            &nbsp; &nbsp; Save my name, email, and website in this browser for
            the next time I comment.
          </label>
        </div>

        <br />
        <HCaptcha
          sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
          reCaptchaCompat={false}
          onVerify={handleCaptchaVerify} // Handle token verification
        />
        <br />
        <br />

        <input type="hidden" name="form_location" value={location.href} />

        {/* <input
          type="hidden"
          name="ccemail"
          value="shenali@3cs.solutions; priyankara@3cs.solutions"
        /> */}
        <input
          type="hidden"
          name="ccemail"
          value="info@bpplholdings.com; admin@3cs.lk"
        />

        <input
          type="hidden"
          name="redirect"
          value="https://web3forms.com/success"
        />

        <input type="checkbox" name="botcheck" style={{ display: "none" }} />
        <input name="from_name" type="hidden" value="BPPL comment" />
        <input name="subject" type="hidden" value="BPPL Leave a Comment Form" />

        <div className="form-footer">
          <button
            type="submit"
            className="btn formSubmitButton btn-lg"
            id="button"
          >
            Post Comment »
          </button>
          <br />
          <br />
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      </form>
    </div>
  );
}

export default App;
