import React, { useEffect } from "react";

const GoogleCSE = () => {
  useEffect(() => {
    const script = document.createElement("script");
    // script.async = true;
    script.defer = true;
    script.src = "https://cse.google.com/cse.js?cx=d7c8484a53cca4f47";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="gcse-search"></div>;
};

export default GoogleCSE;
