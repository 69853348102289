import React, { Suspense, lazy } from "react";
import ScrollToTopButton from "./scroll-to-top-button";
// import "../styling/component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import TW_Logo from "../images/tw-logo.webp";
//import BW_Logo from "../images/bestweb-24-vote4us.webp";
const AccessibilityWidgetComponent = lazy(() =>
  import("./AccessibilityWidget")
);

export default function Footer() {
  return (
    <div>
      <Suspense fallback={<span></span>}>
        <AccessibilityWidgetComponent />
      </Suspense>

      <div
        className="footer"
        style={{
          background:
            "linear-gradient(to right, #01114f, #121d6d,#086058, #fece14)",
          height: "auto",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <center>
          <div className="section-background">
            <div className="footer-two-column">
              <div className="footer-top-table">
                <div className="social-icon">
                  <center>
                    <div className="social-media-gap">
                      <h2 className="social-topic"> Follow BPPL</h2>
                      <div className="footer-mobile">
                        <br />
                      </div>
                      <div className="social-media-top"></div>

                      <a
                        href="https://www.facebook.com/bpplholdings"
                        target="_blank"
                        className="social-media"
                      >
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                      <a
                        href="https://www.instagram.com/bpplholdings/"
                        target="_blank"
                        className="social-media"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCcDxAIkOrJj4vUwHLWjUJHA/playlists"
                        target="_blank"
                        className="social-media"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/3300235/admin/"
                        target="_blank"
                        className="social-media"
                      >
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                    </div>
                  </center>
                </div>
              </div>

              <div
                className="two-column-text-footer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginTop: "55px" }}>
                  <center>
                    <div className="footer-font">
                      <p className="footer-font-1">
                        <div className="footer-font">
                          <ul className="quick-links-lists">
                            <li>
                              <a href="/">Home</a>
                            </li>
                            <li>
                              <a href="/about-us/">About Us</a>
                            </li>
                            <li>
                              <a href="/esg/">ESG</a>
                            </li>
                            <li>
                              <a href="/investor-relations/">
                                Investor Relations
                              </a>
                            </li>
                            <li>
                              <a href="/contacts/">Contacts</a>
                            </li>
                          </ul>
                        </div>
                        <div className="footer-font">
                          <ul className="quick-links-lists">
                            <li>
                              <a href="/cookies-policy/">COOKIE POLICY</a>
                            </li>
                            <li>
                              <a href="/privacy-policy/">PRIVACY POLICY</a>
                            </li>
                            <li>
                              <a href="/sitemap/">SITEMAP</a>
                            </li>
                          </ul>
                        </div>

                        <div className="footer-font-2">
                          Web Design by{" "}
                          <a className="name-link" href="https://www.3cs.lk/">
                            3CS
                          </a>
                        </div>

                        <div
                          className="footer-bw-tw-logos"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            marginBottom: "-10px",
                          }}
                        >
                          <a href="https://topweb.lk/may2024/bpplholdings">
                            <img
                              src={TW_Logo}
                              loading="lazy"
                              alt="logo"
                              width="auto"
                              height="100"
                              style={{ borderRadius: "5%" }}
                            />
                          </a>
                          {/* <a href="https://www.vote.bestweb.lk/site/www_bpplholdings_lk">
                            <img
                              src={BW_Logo}
                              loading="lazy"
                              alt="logo"
                              width="auto"
                              height="100"
                              style={{ marginLeft: "15%" }}
                            />
                          </a> */}
                        </div>
                      </p>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </center>

        <br />
      </div>
      <ScrollToTopButton />
    </div>
  );
}
